<template>
  <v-card-text>
    <p class="text-2xl font-weight-semibold text--primary mb-2">
      Buat Akun
    </p>
    <p class="mb-8">
      Selamat datang di Oriens CRM! 👋 Harap lengkapi data berikut untuk membuat akun baru.
    </p>
    <v-form
      ref="registerOneForm"
      class="mt-4"
      @submit.prevent="next"
    >
      <v-text-field
        v-model="inputData.email"
        outlined
        type="email"
        label="Email"
        placeholder="Input email"
        hide-details="auto"
        class="mb-4"
        :rules="[required, emailValidator]"
      />
      <div class="d-flex flex-column flex-md-row">
        <v-text-field
          v-model="inputData.first_name"
          outlined
          label="Nama Awal"
          placeholder="Input nama awal"
          hide-details="auto"
          class="mb-4 mr-md-2"
          :rules="[required]"
        />
        <v-text-field
          v-model="inputData.last_name"
          outlined
          label="Nama Akhir"
          placeholder="Input nama akhir"
          hide-details="auto"
          class="mb-4 ml-md-2"
        />
      </div>
      <v-phone-number
        v-model="inputData.phone"
        flat
        outlined
        label="Nomor HP"
        placeholder="Input nomor HP"
        hide-details="auto"
        default-country="ID"
        required
        strict
      />
      <v-btn
        block
        color="primary"
        type="submit"
        class="mt-6"
      >
        Buat Akun
      </v-btn>

      <div class="d-flex align-center justify-center flex-wrap mt-4">
        <router-link to="/login">
          Sudah punya akun?
        </router-link>
      </div>
    </v-form>
  </v-card-text>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { required, emailValidator, phoneValidator } from '@core/utils/validation'
import VPhoneNumber from 'v-phone-number'

export default {
  components: {
    VPhoneNumber,
  },
  props: {
    value: {
      type: Object,
      default: null,
      required: true,
    },
  },
  setup(props, { emit }) {
    const registerOneForm = ref()
    const inputData = computed({
      get() {
        return props.value
      },
      set(val) {
        emit('input', val)
      },
    })

    const next = () => {
      if (registerOneForm.value.validate()) {
        emit('next')
      }
    }

    return {
      registerOneForm,
      inputData,
      next,

      required,
      emailValidator,
      phoneValidator,
    }
  },
}
</script>

<style>

</style>
